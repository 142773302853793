<template>
  <div style="padding: 10px">
    <div style="margin: 10px 0" v-if="this.userId === 1">
      <el-button type="primary" @click="add">新增</el-button>
    </div>
    <!--搜索区域-->
    <div style="margin: 10px 0">
      <el-input v-model="search.clubName" placeholder="请输入俱乐部名称" @keyup.enter.native="load" style="width: 20%"
                clearable></el-input>
      <el-date-picker value-format="YYYY-MM-DD" format="YYYY-MM-DD" v-model="formSource.computeDate"></el-date-picker>
      <el-button type="primary" style="margin-left: 5px" @click="load">查询</el-button>
      <el-button type="success" style="margin-left: 5px" @click="sourceGroup">资源分组</el-button>
      <el-button type="success" style="margin-left: 5px" @click="exportExcel">资源分组导出</el-button>
      <el-button style="margin-left: 5px" @click="resetData">重置</el-button>
    </div>
    <el-table
            v-loading="loading"
            :data="tableData"
            border
            stripe
            style="width: 100%">
      <el-table-column
              prop="clubName"
              label="俱乐部名称">
      </el-table-column>
      <el-table-column
              prop="clubCode"
              label="俱乐部编号">
      </el-table-column>
      <el-table-column
              prop="realCount"
              label="当前人数">
      </el-table-column>
      <el-table-column
              prop="maxCount"
              label="人数上限">
      </el-table-column>
      <el-table-column
              prop="inUse"
              :formatter="inUseFormatter"
              label="兔马俱乐部">
      </el-table-column>
      <el-table-column
              prop="sourceOne"
              label="果篮/的卢">
      </el-table-column>
      <el-table-column
              prop="sourceTwo"
              label="捣药/绝影">
      </el-table-column>
      <el-table-column
              prop="sourceThree"
              label="钉耙/骅骝">
      </el-table-column>
      <el-table-column
              prop="sourceFour"
              label="伐桂/赤兔">
      </el-table-column>
      <el-table-column
              prop="sourceFive"
              label="射日/紫骍">
      </el-table-column>
      <el-table-column
              prop="sourceSix"
              label="金蟾/惊帆">
      </el-table-column>
      <el-table-column
              prop="sourceSeven"
              label="浇桂/大宛">
      </el-table-column>
      <el-table-column
              prop="sourceEight"
              label="白玉/彩玉">
      </el-table-column>
      <el-table-column label="操作" width="80px" v-if="this.userId === 1">
        <template #default="scope">
          <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div style="margin: 10px 0">
      <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[5, 10, 20]"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
      </el-pagination>
    </div>
    <!--新增编辑表单-->
    <el-dialog title="新增/编辑" v-model="dialogVisible" width="30%">
      <el-form :model="form">
        <el-form-item label="俱乐部名称">
          <el-input v-model="form.clubName" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="人数上限">
          <el-input type="number" v-model="form.maxCount" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="兔马活动">
          <el-select v-model="form.inUse" placeholder="是否活动俱乐部" filterable>
            <el-option
                    v-for="item in inUseDict"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="果篮/的卢">
          <el-input type="number" v-model="form.sourceOne" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="捣药/绝影">
          <el-input type="number" v-model="form.sourceTwo" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="钉耙/骅骝">
          <el-input type="number" v-model="form.sourceThree" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="伐桂/赤兔">
          <el-input type="number" v-model="form.sourceFour" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="射日/紫骍">
          <el-input type="number" v-model="form.sourceFive" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="金蟾/惊帆">
          <el-input type="number" v-model="form.sourceSix" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="浇桂/大宛">
          <el-input type="number" v-model="form.sourceSeven" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="白玉/彩玉">
          <el-input type="number" v-model="form.sourceEight" style="width: 80%"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="save">确 定</el-button>
          </span>
      </template>
    </el-dialog>

    <!--资源分组展示-->
    <el-dialog title="分组情况" v-model="dialogVisibleSource" width="80%">
      <el-form :model="formSource">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="参加活动人数">
              <el-input v-model="formSource.goodUserCount" style="width: 50%"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="未参加活动人数">
              <el-input v-model="formSource.badUserCount" style="width: 50%"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="参加活动名单">
              <el-input type="textarea" v-model="formSource.goodUserNameList" :autosize="{minRows:4}"
                        style="width: 80%"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="未参加活动名单">
              <el-input type="textarea" v-model="formSource.badUserNameList" :autosize="{minRows:4}"
                        style="width: 80%"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <span style="color: red">{{ formSource.result }}</span>
          </el-col>
        </el-row>
        <el-table
                :data="tableDataSource"
                border
                :span-method="objectSpanMethod"
        >
          <el-table-column
                  v-for="col in columns"
                  :key="col.prop"
                  :prop="col.prop"
                  :label="col.label"
                  align="center"
          >
            <template #default="scope">
              <!-- 动态匹配格式化逻辑 -->
              <template v-if="col.prop === 'clubCode'">
                {{ formatClubCode(scope.row) }}
              </template>
              <template v-else-if="col.prop.startsWith('source')">
                {{ scope.row[col.prop] }}
              </template>
              <template v-else>
                {{ scope.row[col.prop] }}
              </template>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="saveSource">确 定</el-button>
          </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>

import request from "@/utils/request";

export default {
  name: 'clubInfo',
  components: {},
  data() {
    return {
      exportUrl: "http://localhost:9090/clubInfo/exportSource",
      tableDataSource: [],      // 展平后的表格数据
      spanArr: [],        // 记录合并规则的数组
      clubCodeDict: [],
      // 表头配置
      columns: [
        {prop: "code", label: "分组编号"},
        {prop: "clubCode", label: "俱乐部"},
        {prop: "sourceOne", label: "果篮/的卢"},
        {prop: "sourceTwo", label: "捣药/绝影"},
        {prop: "sourceThree", label: "钉耙/骅骝"},
        {prop: "sourceFour", label: "伐桂/赤兔"},
        {prop: "sourceFive", label: "射日/紫骍"},
        {prop: "sourceSix", label: "金蟾/惊帆"},
        {prop: "sourceSeven", label: "浇桂/大宛"}
      ],
      inUseDict: [{"value": 1, "label": "是"}, {"value": 0, "label": "否"}],
      dialogVisible: false,
      dialogVisibleSource: false,
      form: {},
      formSource: {},
      userId: '',
      loading: true,
      search: {},
      query: {},
      currentPage: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
    }
  },
  created() {
    this.load()
    let userStr = sessionStorage.getItem("user") || "{}"
    let user = JSON.parse(userStr)
    this.userId = user.id;
  },
  mounted() {
    this.getClubCodeDict()
  },
  methods: {
    exportExcel() {
      if (!this.formSource.computeDate) {
        this.$message.error("请选择日期")
        return;
      }
      location.href = this.exportUrl + "?date=" + this.formSource.computeDate;
    },
    getClubCodeDict() {
      request.get("/clubInfo/listNoPage", {}).then(res => {
        this.loading = false
        this.clubCodeDict = res.data
      })
    },
    // 点击新增按钮
    add() {
      this.dialogVisible = true
      this.form = {}
    },
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row))
      this.dialogVisible = true
    },
    save() {
      this.loading = true;
      if (this.form.id) {  // 更新
        request.post("/clubInfo/update", this.form).then(res => {
          if (res.code === '0') {
            this.$message({
              type: "success",
              message: "更新成功"
            })
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.load() // 刷新表格的数据
          this.loading = false
          this.dialogVisible = false  // 关闭弹窗
        })
      } else {  // 新增
        request.post("/clubInfo/save", this.form).then(res => {
          if (res.code === '0') {
            this.$message({
              type: "success",
              message: "新增成功"
            })
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.form = '';
          this.load(); // 刷新表格的数据
          this.loading = false
          this.dialogVisible = false  // 关闭弹窗
        })
      }
    },
    handleUploadSuccess(res) {
      if (res.code === "0") {
        this.$message.success("导入成功")
        this.load()
      }
    },
    resetData() {
      this.search = {}
      this.load()
    },
    sourceGroup() {
      if (!this.formSource.computeDate) {
        this.$message.error("请选择日期")
        return;
      }
      this.loading = true
      this.dialogVisibleSource = true;
      request.post("/clubInfo/computeSource", this.formSource).then(res => {
        this.loading = false
        this.formSource = res.data
        this.processData(this.formSource.sourceInfoDTOList);
      })
    },
    // 数据展平并生成合并规则
    processData(sourceData) {
      const flatData = [];
      this.spanArr = [];
      sourceData.forEach(item => {
        const children = item.childInfoDTOList;
        // 记录合并行数（当前code对应的子项数量）
        this.spanArr.push(children.length);
        // 展平数据，每个子项保留code
        children.forEach(child => {
          flatData.push({...child, code: item.code});
        });
      });
      this.tableDataSource = flatData;
    },
    objectSpanMethod({row, column, rowIndex, columnIndex}) {
      if (columnIndex === 0) { // 只处理第一列（code列）
        let currentSpan = 0;
        let pos = 0; // 当前遍历位置
        // 遍历spanArr，找到当前行对应的合并规则
        for (let i = 0; i < this.spanArr.length; i++) {
          currentSpan = this.spanArr[i];
          pos += currentSpan;
          if (rowIndex < pos) {
            // 如果是当前code的第一行，返回合并行数
            if (rowIndex === pos - currentSpan) {
              return {rowspan: currentSpan, colspan: 1};
            } else {
              return {rowspan: 0, colspan: 0}; // 其他行隐藏
            }
          }
        }
      }
      // 其他列不合并
      return {rowspan: 1, colspan: 1};
    },
    load() {
      this.loading = true
      const searchForm = {...this.search}
      let obj = Object.assign(searchForm, this.query)
      let reqData = {
        ...obj
      }
      reqData.current = this.currentPage
      reqData.size = this.pageSize
      request.get("/clubInfo/list", {
        params: reqData
      }).then(res => {
        this.loading = false
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    handleSizeChange(pageSize) {   // 改变当前每页的个数触发
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {  // 改变当前页码触发
      this.currentPage = pageNum
      this.load()
    },
    inUseFormatter(row) {
      if (row.inUse === null) {
        return "未知";
      }
      const list = this.inUseDict;
      if (list) {
        for (let i = 0; i < list.length; i++) {
          if (list[i].value === row.inUse) {
            return list[i].label
          }
        }
      }
    },
    formatClubCode(row) {
      if (row.clubCode === null || row.clubCode === -1) {
        return "无";
      }
      const list = this.clubCodeDict;
      if (list) {
        for (let i = 0; i < list.length; i++) {
          if (list[i].clubCode === row.clubCode) {
            return list[i].clubName
          }
        }
      }
    },
  }
}
</script>
